// Adapted from https://github.com/loomchild/webflow-alpinejs.

import { initTabs } from './tabs'

const getAlpineAttributes = (el: HTMLElement) => {
    const alpineAttributes = []
    for (let i = 0; i < el.attributes.length; ++i) {
        const a = el.attributes[i]
        if (a?.name.startsWith('x-')) {
            alpineAttributes.push(a)
        }
    }
    return alpineAttributes
}

const wrapInTemplate = (el: HTMLElement) => {
    if (el.tagName === 'TEMPLATE') {
        return
    }

    const template = document.createElement('template')

    const attributes = getAlpineAttributes(el)
    attributes.forEach(a => {
        if (!a) {
            return
        }

        template.setAttribute(a.name, a.value)
        el.removeAttribute(a.name)
    })

    el.parentNode?.insertBefore(template, el)
    template.content.appendChild(el)
}

const replaceDotAttributes = (el: HTMLElement) => {
    const attributes = getAlpineAttributes(el)

    attributes.forEach(a => {
        if (!a) {
            return
        }

        const m = a.name.match(/^(x-[^:]+)(:.+)$/)
        if (m) {
            let newA = null
            if (['x-bind', 'x-on'].includes(m[1] || '')) {
                let prefix = m[1]
                let suffix = (m[2] || '').substring(1)
                if (prefix === 'x-on' && suffix.startsWith('update:')) {
                    prefix += ':update'
                    suffix = suffix.substring(7)
                }
                if (suffix.includes(':')) {
                    newA = prefix + ':' + suffix.replace(/:/g, '.')
                }
            } else {
                newA = m[1] + (m[2] || '').replace(/:/g, '.')
            }
            if (newA) {
                el.setAttribute(newA, a.value)
                el.removeAttribute(a.name)
            }
        }
    })
}

const removeUnnecessaryAttributeValues = (el: HTMLElement) => {
    const attributes = getAlpineAttributes(el)

    attributes.forEach(a => {
        if (!a) {
            return
        }

        if (a.name.match(/^x-transition.*(?!(enter|leave))/)) {
            el.setAttribute(a.name, '')
        }
    })
}

// If we have a radio with x-bind:value Alpine will not set the value if there's an existing value attribute but
// even if value is empty in Webflow it will still output value="". This method removes the empty value attribute on
// radio buttons where value is bound.
const removeEmptyBoundRadioValues = () => {
    document.querySelectorAll('input[type="radio"][x-bind\\:value][value=""]').forEach(el => {
        el.removeAttribute('value')
    })
}

const init = () => {
    document.querySelectorAll<HTMLElement>('[x-data],[x-data] *').forEach((el) => {
        replaceDotAttributes(el)
        removeUnnecessaryAttributeValues(el)
    })

    removeEmptyBoundRadioValues()

    document.querySelectorAll<HTMLElement>('[x-data] [x-for], [x-data] [x-if]').forEach(wrapInTemplate)

    initTabs()
}

declare global {
    interface Window {
        Webflow: any,
        fsAttributes: any,
        loadAlpine: typeof loadAlpine
    }
}


export const loadAlpine = (version?: string) => {
    version = version || '3.x.x'

    window.Webflow = window.Webflow || [] as any
    window.Webflow.push(() => {
        init()

        const script = document.createElement('script')
        script.src = `https://cdn.jsdelivr.net/npm/alpinejs@${version}/dist/cdn.min.js`
        document.head.appendChild(script)
    })

    window.fsAttributes = window.fsAttributes || []
    window.fsAttributes.push(
        [
            'cmsload',
            (listInstances: any[]) => {
                init()

                listInstances.forEach(instance => {
                    instance.on('renderitems', () => init())
                })
            }
        ]
    )
}

window.loadAlpine = loadAlpine

import { Alpine as AlpineType } from 'alpinejs'

declare global {
    var Alpine: AlpineType
}

export const initTabs = () => {
    document.addEventListener('alpine:init', () => {
        Alpine.data('tabs', (targetEl = null) => ({
            tabs: null as null | HTMLElement,
            tab: 0,
            _tabCount: 0,

            get tabCount () {
                return this._tabCount
            },

            nextTab () {
                if (this.tab + 1 < this.tabCount) {
                    this.tab++
                }
            },

            previousTab () {
                if (this.tab > 0) {
                    this.tab--
                }
            },

            init () {
                if (targetEl) {
                    this.tabs = document.querySelector<HTMLElement>(targetEl as string)
                } else {
                    this.tabs = this.$el.classList.contains('w-tabs') ? this.$el : this.$el.querySelector('.w-tabs')
                }

                if (!this.tabs) {
                    throw new Error('Missing tabs component to target')
                }

                const config = { attributes: true, childList: false, subtree: false, attributeFilter: ['class'] }

                const setObserver = (target: HTMLElement, index: number) => {
                    const observer = new MutationObserver((mutations) => {
                        if (this.tab === index) {
                            return
                        }

                        mutations.forEach((mutation) => {
                            const target = mutation.target as HTMLElement
                            if (target.classList.contains('w--current')) {
                                this.tab = index
                            }
                        })
                    })
                    observer.observe(target, config)
                }

                const tabs: HTMLElement[] = this.tabs.querySelectorAll(':scope > .w-tab-menu > .w-tab-link')
                this._tabCount = tabs.length
                tabs.forEach((tab, index) => {
                    setObserver(tab, index)
                })

                this.$dispatch('update:tab', this.tab)
                this.$watch('tab', (index) => {
                    const tab = this.tabs.querySelector(`:scope > .w-tab-menu > .w-tab-link:nth-child(${index + 1})`)
                    if (tab && !tab.classList.contains('w--current')) {
                        tab.dispatchEvent(new MouseEvent('click', { view: window, bubbles: true, cancelable: true }))
                    }
                    this.$dispatch('update:tab', index)
                })
            }
        }))
    })
}